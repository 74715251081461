<template>
  <div>
    <h5 class="text-primary">{{ $t("my_product_reviews") }}</h5>
    <div v-if="!isReviewDetails">
      <div v-if="!reviews.length">
        <p>No reviews yet.</p>
        <router-link to="/shop" class="btn btn-outline-primary-2">
          <span>GO SHOP</span>
          <i class="icon-long-arrow-right"></i>
        </router-link>
      </div>
      <div v-else>
        <div
          class="d-flex d-md-none flex-column align-items-end py-4 border-bottom"
          v-for="(review, index) in paginatedData"
          :key="index"
        >
          <div class="d-flex gap-1">
            <div>
              <h6>{{ $t("product_name") }}</h6>
              <h6>Angemaakt Waardering Review</h6>
            </div>
            <div>
              <h5 class="mb-0">{{ review.product.name }}</h5>
              <p class="text-dark mb-0">{{ review.created_at }}</p>
              <font-awesome-icon
                v-for="(_, index) in Array.from({
                  length: review.average_rating,
                }).fill('')"
                :key="index"
                icon="fa-solid fa-star"
                class="text-primary"
              />
              <p class="text-dark">{{ review.summary }}</p>
            </div>
          </div>
          <button class="btn btn-dark" @click="getReviewDetails(review)">
            {{ $t("check_details") }}
          </button>
        </div>

        <table class="table d-none d-md-table">
          <thead>
            <tr>
              <th>{{ $t("made") }}</th>
              <th>{{ $t("product_name") }}</th>
              <th>{{ $t("rating") }}</th>
              <th>{{ $t("review") }}</th>
              <th>{{ $t("action") }}</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(review, index) in paginatedData" :key="index">
              <td style="width: 15%">{{ review.created_at }}</td>
              <td style="width: 15%">{{ review.product.name }}</td>
              <td style="width: 15%" v-if="review.average_rating">
                <div class="ratings mr-2">
                  <div
                    class="ratings-val"
                    :style="{
                      width: (review.average_rating / 100) * 5 * 20 + '%',
                    }"
                  ></div>
                  <span class="tooltip-text">{{
                    (review.average_rating / 100) * 5
                  }}</span>
                </div>
              </td>
              <td style="width: 15%" v-else>_</td>
              <td style="width: 30%">{{ review.summary }}</td>
              <td style="width: 20%">
                <button class="btn btn-dark" @click="getReviewDetails(review)">
                  {{ $t("check_details") }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <pagination-comp
          :per-page="perPage"
          :total="totalCount"
          @handlePerPage="handlePerPage"
        />
      </div>
    </div>

    <div v-else>
      <button @click="viewAllReview">
        <font-awesome-icon icon="fa-solid fa-arrow-left" />
      </button>
      <review-details :review="this.reviewDetails"></review-details>
    </div>
  </div>
</template>

<script>
import ReviewDetails from "@/esf_weert_mobilesupplies/core/components/elements/ReviewDetails.vue";
import PaginationComp from "@/esf_weert_mobilesupplies/core/components/elements/PaginationComp";
// import { reviewsData } from '@/esf_weert_mobilesupplies/utilities/data';

export default {
  components: {
    ReviewDetails,
    PaginationComp,
  },
  data() {
    return {
      isReviewDetails: false,
      reviewDetails: null,
      // pagination
      perPage: 24,
      totalCount: 0,
      // pagination
    };
  },
  mounted() {
    this.totalCount = this.reviews.length;
  },
  watch: {
    reviews(items) {
      this.totalCount = items.length;
    },
  },
  computed: {
    reviews: function () {
      return this.$store.getters["user/getReviews"];
    },
    // pagination
    currentPage: function () {
      return parseInt(this.$route.query.page ? this.$route.query.page : 1);
    },
    paginatedData() {
      if (!this.reviews) return [];

      let start = (this.currentPage - 1) * this.perPage;
      let end = start + this.perPage;

      // Apply sorting based on sortBy
      let sortedCategories = [...this.reviews];
      if (this.sortBy === "price_low_high") {
        sortedCategories.sort((a, b) => a.price - b.price);
      } else if (this.sortBy === "price_high_low") {
        sortedCategories.sort((a, b) => b.price - a.price);
      }
      // Add more sorting logic if needed

      return sortedCategories.slice(start, end);
    },
    // pagination
  },
  methods: {
    getReviewDetails(review) {
      this.isReviewDetails = !this.isReviewDetails;
      this.reviewDetails = review;
    },
    viewAllReview() {
      this.isReviewDetails = !this.isReviewDetails;
    },
    // pagination
    handlePerPage: function (count) {
      this.perPage = count;
    },
    // pagination
  },
};
</script>

<template>
  <div>
    <h5 class="text-primary">{{ $t("wishlist") }}</h5>
    <div v-if="wishlistItems.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("Article") }}</th>
            <th>{{ $t("no_of") }}</th>
            <th>{{ $t("unit_price") }}</th>
          </tr>
        </thead>
      </table>
      <div class="row" v-for="wishlist in paginatedData" :key="wishlist.id">
        <div class="col-12 d-flex">
          <wishlist-product :product="wishlist"></wishlist-product>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-md-12 text-right">
          <button class="btn btn-dark">
            {{ $t("share_wishlist") }}
          </button>
        </div>
      </div>
      <pagination-comp
        :per-page="perPage"
        :total="totalCount"
        @handlePerPage="handlePerPage"
      />
    </div>
    <div v-else>
      <p>No item available yet.</p>
      <router-link to="/shop" class="btn btn-outline-primary-2">
        <span>GO SHOP</span>
        <i class="icon-long-arrow-right"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import WishlistProduct from "@/esf_weert_mobilesupplies/core/components/elements/WishlistProduct.vue";
import PaginationComp from "@/esf_weert_mobilesupplies/core/components/elements/PaginationComp";

export default {
  components: {
    PaginationComp,
    WishlistProduct,
  },
  data() {
    return {
      // pagination
      perPage: 24,
      totalCount: 0,
      // pagination
    };
  },
  computed: {
    wishlistItems() {
      return this.$store.getters["wishlist/getWishItems"];
    },
    // pagination
    currentPage: function () {
      return parseInt(this.$route.query.page ? this.$route.query.page : 1);
    },
    paginatedData() {
      if (!this.wishlistItems) return [];

      let start = (this.currentPage - 1) * this.perPage;
      let end = start + this.perPage;

      // Apply sorting based on sortBy
      let sortedCategories = [...this.wishlistItems];
      if (this.sortBy === "price_low_high") {
        sortedCategories.sort((a, b) => a.price - b.price);
      } else if (this.sortBy === "price_high_low") {
        sortedCategories.sort((a, b) => b.price - a.price);
      }
      // Add more sorting logic if needed

      return sortedCategories.slice(start, end);
    },
    // pagination
  },
  mounted() {
    this.totalCount = this.wishlistItems.length;
  },
  watch: {
    wishlistItems(items) {
      this.totalCount = items.length;
    },
  },
  methods: {
    // pagination
    handlePerPage: function (count) {
      this.perPage = count;
    },
    // pagination
  },
};
</script>

<template>
  <div class="review-details">
    <div class="review-image">
      <img :src="review.product.image.url" width="200" alt="" />
    </div>
    <div class="review-meta">
      <p class="title" :title="review.name">{{ productName }}</p>
      <div>
        <strong>Kwaliteit</strong>
        <div class="ratings ml-2">
          <div
            class="ratings-val"
            :style="{
              width: (review.ratings_breakdown[0].value / 5) * 100 + '%',
            }"
          ></div>
          <span class="tooltip-text">{{
            review.ratings_breakdown[0].value
          }}</span>
        </div>
      </div>
      <!-- <font-awesome-icon
                v-for="(_, index) in Array.from({ length: product.average_rating }).fill('')" :key="index"
                icon="fa-solid fa-star" class="text-primary" /> -->

      <div>
        <strong>Prijs</strong>
        <div class="ratings ml-2">
          <div
            class="ratings-val"
            :style="{
              width: (review.ratings_breakdown[1].value / 5) * 100 + '%',
            }"
          ></div>
          <span class="tooltip-text">{{
            review.ratings_breakdown[1].value
          }}</span>
        </div>
      </div>
      <!-- <font-awesome-icon
                    v-for="(_, index) in Array.from({ length: product.average_rating }).fill('')" :key="index"
                    icon="fa-solid fa-star" class="text-primary" /> -->
      <p>
        Gereviewd door <strong>Jan</strong> ({{ review.nickname }}) op
        {{ review.created_at }}
      </p>
      <p>{{ review.summary }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    review: Object,
  },
  data() {
    return {
      qty: 1,
      nameMaxLength: 20,
    };
  },
  computed: {
    productName() {
      if (this.review.product.name.length <= this.nameMaxLength) {
        return this.review.product.name;
      } else {
        const words = this.review.product.name.split(" ");
        let shortenedString = "";
        let count = 0;
        for (let i = 0; i < words.length; i++) {
          if (count + words[i].length <= this.nameMaxLength) {
            shortenedString += words[i] + " ";
            count += words[i].length;
          } else {
            break;
          }
        }
        return shortenedString.trim() + "...";
      }
    },
  },
  methods: {
    changeQty: function (current) {
      this.qty = current;
    },
  },
};
</script>

<template>
  <div>
    <h5 class="text-primary">{{ $t("my_details") }}</h5>
    <h6 class="border-bottom mt-2 pb-1">{{ $t("contact_details") }}</h6>
    <form action="#">
      <div class="row">
        <div class="col-sm-6">
          <b-form-group
            class="account-inputs"
            id="first-name-group"
            :label="$t('form_first_name') + '*'"
            label-for="first_name"
          >
            <b-form-input v-validParent 
              id="first_name"
              v-model="form.firstname"
              type="text"
              v-on:blur="validateFirstname()"
              :state="form.firstnameState"
              aria-describedby="firstname-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="firstname-feedback">
              {{ form.firstnameError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <b-form-group
            id="last-name-group"
            :label="$t('form_last_name') + '*'"
            label-for="last_name"
          >
            <b-form-input v-validParent 
              id="last_name"
              v-model="form.lastname"
              type="text"
              v-on:blur="validateLastname()"
              :state="form.lastnameState"
              aria-describedby="lastname-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="lastname-feedback">
              {{ form.lastnameError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <input type="checkbox" id="address" v-model="isAddress" />
          <label class="ml-2" for="address"> {{ $t("change_email2") }}</label>
        </div>
        <div class="col-sm-12 d-flex align-items-center">
          <input type="checkbox" id="changePass" v-model="isPassword" />
          <label class="ml-2" for="changePass">
            {{ $t("change_password") }}</label
          >
        </div>

        <div class="col-md-12" v-if="isAddress">
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t("change_email2") }}</th>
              </tr>
            </thead>
          </table>
          <div class="row">
            <div class="col-sm-6">
              <b-form-group
                id="email-group"
                :label="$t('email_address_login_form') + '*'"
                label-for="email"
              >
                <b-form-input v-validParent 
                  id="email"
                  v-model="form.email"
                  type="text"
                  v-on:blur="validateEmail()"
                  :state="form.emailState"
                  :placeholder="$t('email_address_login_form')"
                  aria-describedby="email-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="email-feedback">
                  {{ form.emailError }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <label>{{ $t("current_password") }} *</label>
              <input type="text" class="form-control" required />
            </div>
          </div>
        </div>

        <div class="col-md-12" v-if="isPassword">
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t("change_password") }}</th>
              </tr>
            </thead>
          </table>
          <div class="row">
            <div class="col-sm-6">
              <b-form-group
                class="account-inputs password-watch"
                id="password-group"
                :label="$t('form_current_password') + '*'"
                label-for="password"
              >
                <b-form-input v-validParent 
                  id="current_password"
                  v-model="form.current_password"
                  :type="form.passwordFieldType.current_password"
                  :state="form.current_passwordState"
                  aria-describedby="current_password-feedback"
                ></b-form-input>
                <span class="show-password">
                  <b-link
                    class="span-txt"
                    @click="toggleShowPass($event, 'current_password')"
                  >
                    {{
                      form.passwordFieldType.current_password === "password"
                        ? $t("show")
                        : $t("hide")
                    }}
                  </b-link>
                </span>
                <b-form-invalid-feedback id="current_password-feedback">
                  {{ form.current_passwordError }}
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- <label>{{ $t('current_password') }} *</label>
                            <input type="text" class="form-control" required /> -->
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b-form-group
                class="account-inputs password-watch"
                id="password-group"
                :label="$t('form_password') + '*'"
                label-for="password"
              >
                <b-form-input v-validParent 
                  id="password"
                  v-model="form.password"
                  :type="form.passwordFieldType.password"
                  :state="form.passwordState"
                  aria-describedby="password-feedback"
                ></b-form-input>
                <span class="show-password">
                  <b-link
                    class="span-txt"
                    @click="toggleShowPass($event, 'password')"
                  >
                    {{
                      form.passwordFieldType.password === "password"
                        ? $t("show")
                        : $t("hide")
                    }}
                  </b-link>
                </span>
                <client-only>
                  <password-meter :password="form.password" />
                </client-only>
                <b-form-invalid-feedback id="password-feedback">
                  {{ form.passwordError }}
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- <label>{{ $t('new_password') }} *</label>
                            <input type="text" class="form-control" required /> -->
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b-form-group
                class="account-inputs password-watch"
                id="password_confirm-group"
                :label="$t('form_password_confirmation') + '*'"
                label-for="password_confirm"
              >
                <b-form-input v-validParent 
                  id="password_confirm"
                  v-model="form.password_confirm"
                  :type="form.passwordFieldType.password_confirm"
                  :state="form.password_confirmState"
                  aria-describedby="password_confirm-feedback"
                ></b-form-input>
                <span class="show-password">
                  <b-link
                    class="span-txt"
                    @click="toggleShowPass($event, 'password_confirm')"
                  >
                    {{
                      form.passwordFieldType.password_confirm === "password"
                        ? $t("show")
                        : $t("hide")
                    }}
                  </b-link>
                </span>
                <b-form-invalid-feedback id="password_confirm-feedback">
                  {{ form.password_confirmError }}
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- <label>{{ $t('confirm_new_password') }} *</label>
                            <input type="text" class="form-control" required /> -->
            </div>
          </div>
        </div>

        <div class="col-sm-12 text-right">
          <button type="button" @click="submit" class="btn btn-dark">
            <span>{{ $t("save") }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { checkEmailExist } from "@storefront/core/data-resolver/user";
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";

export default {
  data() {
    return {
      isAddress: false,
      isPassword: false,
      form: {
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        emailname: "",
        emailState: null,
        emailError: "",
        originalEmail: "",
        current_password: "",
        current_passwordState: null,
        current_passwordError: "",
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
        passwordFieldType: {
          password_confirm: "password",
          password: "password",
          current_password: "password",
        },
      },
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  components: {
    passwordMeter: () =>
      !isServer ? import("vue-simple-password-meter") : null,
    ClientOnly,
  },
  computed: {
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
  },
  watch: {
    user() {
      this.form.firstname = this.user.firstname;
      this.form.lastname = this.user.lastname;
      this.form.email = this.user.email;
      this.form.originalEmail = this.user.email;
    },
    Password() {
      this.validatePassword();
    },
    PasswordConfirm() {
      this.validateConfirmationPassword();
    },
  },
  mounted() {
    this.form.firstname = this.user.firstname;
    this.form.lastname = this.user.lastname;
    this.form.email = this.user.email;
    this.form.originalEmail = this.user.email;
    var self = this;
    history.pushState({}, "");
    window.onpopstate = function () {
      self.$store.commit("user/setEditUser", false);
    };
  },
  methods: {
    async submit() {
      if (this.isPassword) {
        let formValid = true;
        if (this.form.current_passwordState != true) {
          this.validateCurrentPassword();
          if (this.current_passwordState != true) {
            formValid = false;
          }
        }
        if (this.form.passwordState != true) {
          this.validatePassword();
          if (this.form.passwordState != true) {
            formValid = false;
          }
        }
        if (this.form.password_confirmState != true) {
          this.validateConfirmationPassword();
          if (this.form.password_confirmState != true) {
            formValid = false;
          }
        }

        if (formValid == true) {
          const retval = await this.$store.dispatch("user/changePassword", {
            currentPassword: this.form.current_password,
            newPassword: this.form.password,
          });
          if (retval == true) {
            const msg = {
              type: "success",
              title: this.$t("account_modify"),
              text: this.$t("account_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.$store.commit("user/setEditPassword", false);
          } else {
            const msg = {
              type: "failure",
              title: this.$t("account_modify"),
              text: this.$t("account_not_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          }
        }
      } else {
        let formValid = true;
        if (this.form.firstnameState != true) {
          this.validateFirstname();
          if (this.form.firstnameState != true) {
            formValid = false;
          }
        }
        if (this.form.lastnameState != true) {
          this.validateLastname();
          if (this.form.lastnameState != true) {
            formValid = false;
          }
        }
        if (this.form.emailState != true) {
          this.validateEmail();
          if (this.form.emailState != true) {
            formValid = false;
          }
        }
        if (formValid == true) {
          const retval = await this.$store.dispatch("user/updateAccount", {
            account: this.form,
          });
          if (retval == true) {
            const msg = {
              type: "success",
              title: this.$t("account_modify"),
              text: this.$t("account_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.$store.commit("user/setEditUser", false);
          } else {
            const msg = {
              type: "failure",
              title: this.$t("account_modify"),
              text: this.$t("account_not_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.$store.commit("user/setEditUser", false);
          }
        }
      }
    },
    cancel() {
      this.$store.commit("user/setEditUser", false);
    },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    async validateEmail() {
      if (this.form.originalEmail == this.form.email) {
        this.form.emailState = true;
        return true;
      }
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      if (!(await checkEmailExist(this.form.email))) {
        this.form.emailExists = true;
        this.form.emailState = false;
        this.form.emailError = this.$t("email_already_exists");
        return false;
      } else {
        this.emailExists = false;
      }
      this.form.emailState = true;
      return true;
    },
    validatePassword() {
      if (this.form.password.length < 8) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_to_short");
      } else if (this.form.password.match(/[a-z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (
        this.form.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[A-Z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/\d/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else {
        this.form.passwordState = true;
      }
    },
    validateConfirmationPassword() {
      if (this.form.password_confirm.length < 8) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_to_short");
      } else if (this.form.password != this.form.password_confirm) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_not_equal");
      } else {
        this.form.password_confirmState = true;
      }
    },

    validateCurrentPassword() {
      if (this.form.current_password == "") {
        this.form.current_passwordState = false;
        this.form.current_passwordError = this.$t("password_is_required_field");
      } else {
        this.form.current_passwordState = true;
      }
    },
    toggleShowPass(event, field) {
      switch (field) {
        case "current_password":
          this.form.passwordFieldType.current_password =
            this.form.passwordFieldType.current_password === "password"
              ? "text"
              : "password";
          break;
        case "password":
          this.form.passwordFieldType.password =
            this.form.passwordFieldType.password === "password"
              ? "text"
              : "password";
          break;
        case "password_confirm":
          this.form.passwordFieldType.password_confirm =
            this.form.passwordFieldType.password_confirm === "password"
              ? "text"
              : "password";
          break;
      }
    },
  },
};
</script>

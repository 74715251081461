<template>
  <div>
    <div v-if="editAddressId == 0">
      <h5 class="text-primary">{{ $t("directory") }}</h5>
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-dashboard">
            <div class="card-body">
              <h3 class="card-title">{{ $t("standard_billing_address") }}</h3>
              <hr class="mt-1" />
              <div class="no-address" v-if="defaultBilling == null">
                {{ $t("no_default_billing_address") }}
              </div>
              <!-- <p>
                                        Jan Jansen
                                        <br />Grotestraat 10
                                        <br />10001 AB Amsterdam
                                        <br />NL
                                        <br />T: +1-234-987-6543
                                    </p> -->
              <div v-if="defaultBilling != null">
                <address>
                  <div>
                    {{ defaultBilling.firstname }} {{ defaultBilling.lastname }}
                  </div>
                  <div v-if="defaultBilling.company != null">
                    {{ defaultBilling.company }}
                  </div>
                  <div>
                    {{ defaultBilling.street.join(" ") }}
                  </div>
                  <div>
                    {{ defaultBilling.city }}, {{ defaultBilling.postcode }}
                  </div>
                  <div>{{ defaultBilling.country_code }}</div>
                  <div>
                    T:
                    <span
                      ><a :href="`tel:` + defaultBilling.telephone">
                        {{ defaultBilling.telephone }}</a
                      ></span
                    >
                  </div>
                  <div v-if="defaultBilling.vat_id != null">
                    {{ $("VAT") + ":" + defaultBilling.vat_id }}
                  </div>
                </address>
                <button
                  class="btn btn-dark mt-1"
                  @click="goToEditAddress(defaultBilling.id)"
                >
                  {{ $t("change_address") }}
                  <i class="icon-edit"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-dashboard">
            <div class="card-body">
              <h3 class="card-title">{{ $t("standard_shipping_address") }}</h3>
              <hr class="mt-1" />
              <div class="no-address" v-if="defaultShipping == null">
                {{ $t("no_default_shipping_address") }}
              </div>
              <div v-if="defaultShipping != null">
                <address>
                  <div>
                    {{ defaultShipping.firstname }}
                    {{ defaultShipping.lastname }}
                  </div>
                  <div v-if="defaultShipping.company != null">
                    {{ defaultShipping.company }}
                  </div>
                  <div>
                    {{ defaultShipping.street.join(" ") }}
                  </div>
                  <div>
                    {{ defaultShipping.city }}, {{ defaultShipping.postcode }}
                  </div>
                  <div>{{ defaultShipping.country_code }}</div>
                  <div>
                    T:<a :href="`tel:` + defaultShipping.telephone">
                      {{ defaultShipping.telephone }}</a
                    >
                  </div>
                  <div v-if="defaultShipping.vat_id != null">
                    VAT:{{ defaultShipping.vat_id }}
                  </div>
                </address>
                <!-- <p>
                                Jan Jansen
                                <br />Grotestraat 10
                                <br />10001 AB Amsterdam
                                <br />NL
                                <br />T: +1-234-987-6543
                            </p> -->
                <button
                  class="btn btn-dark mt-1"
                  @click="goToEditAddress(defaultShipping.id)"
                >
                  {{ $t("change_address") }}
                  <i class="icon-edit"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-6">
        <div class="card card-dashboard">
            <div class="card-body">
                <h3 class="card-title">{{ $t('standard_shipping_address') }}</h3>

                <p>
                    You have not set up this type of address yet.
                    <br />
                    <a href="#">
                        Edit
                        <i class="icon-edit"></i>
                    </a>
                </p>
            </div>
        </div>
    </div> -->
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-dashboard">
            <div class="card-body">
              <h3 class="card-title">
                {{ $t("additional_address_information") }}
              </h3>
              <hr class="mt-1" />
              <p v-if="addressList && addressList.length === 0">
                {{ $t("no_add_other_address") }}
              </p>
              <div class="address-action" v-else>
                <div
                  class="add-address-row"
                  v-for="address in addressList"
                  :key="address.id"
                >
                  <address v-if="defaultShipping != null">
                    <div>
                      {{ address.firstname }}
                      {{ address.lastname }}
                    </div>
                    <div v-if="address.company != null">
                      {{ address.company }}
                    </div>
                    <div>
                      {{ address.street.join(" ") }}
                    </div>
                    <div>{{ address.city }}, {{ address.postcode }}</div>
                    <div>{{ address.country_code }}</div>
                    <div>
                      T:<a :href="`tel:` + address.telephone">
                        {{ address.telephone }}</a
                      >
                    </div>
                    <div v-if="address.vat_id != null">
                      VAT:{{ address.vat_id }}
                    </div>
                  </address>
                  <div class="action-wrap">
                    <b-link class="btn btn-dark" @click="Adjust(address.id)">{{
                      $t("edit_address")
                    }}</b-link>
                    <b-link
                      class="btn btn-dark ml-2"
                      @click="Remove(address.id)"
                      v-if="
                        !(
                          address.default_billing == true ||
                          address.default_shipping == true
                        )
                      "
                      >{{ $t("delete") }}</b-link
                    >
                  </div>
                </div>
              </div>
              <!-- <table class="table table-order" v-else>
                                <thead>
                                    <tr>
                                        <th>
                                            <h5 class="mb-0">{{ $t('additional_address_information')
                                                }}</h5>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(order, index) in orders" :key="index">
                                        <td class="order_no">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div>
                                                    {{ order.order_no }}
                                                    <div>{{ order.date }}</div>
                                                    <div>{{ order.sent_to }}</div>
                                                    <div>{{ order.total }}</div>
                                                    <div class="order_status">
                                                        <span class="delivered" v-if="order.status === 'Delivered'">
                                                            {{ order.status }}
                                                        </span>
                                                        <span class="cancelled" v-if="order.status === 'Cancelled'">
                                                            {{ order.status }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button class="btn btn-dark mt-1" @click="Adjust(address.id)">
                                                        {{ $t('change_address') }}
                                                        <i class="icon-edit"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> -->
            </div>
          </div>
        </div>
      </div>
      <div class="form-footer-wrap login-btn">
        <b-button variant="success" @click="newAddress()">
          {{ $t("add_address") }}
        </b-button>
        <!-- <b-button variant="cancel" @click="goBack()">{{
                    $t("back")
                    }}</b-button> -->
      </div>
    </div>
    <AddressEdit v-if="editAddressId != 0" />
  </div>
</template>

<script>
import AddressEdit from "@/esf_weert_mobilesupplies/core/components/partial/Account/AddressEdit.vue";
// import { orderData } from '@/esf_weert_mobilesupplies/utilities/data';

export default {
  components: { AddressEdit },
  // data() {
  //     return {
  //         isEditAddress: false,
  //         isCompany: false,
  //         selectedAddress: null,
  //     };
  // },
  mounted() {
    var self = this;
    window.onpopstate = function (event) {
      event.preventDefault();
      self.$store.commit("user/setEditAddresses", false);
    };
    document.documentElement.scrollTo({ top: 0 });
  },
  computed: {
    // orders: function () {
    //     return orderData;
    // },
    // countries() {
    //     return this.$store.getters["user/getCountries"];
    // },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    editAddressId: {
      get() {
        return this.$store.getters["user/getEditAddressId"];
      },
      set(val) {
        this.$store.commit("user/setEditAddressId", val);
        window.scrollTo({
          top: 0,
        });
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    defaultBilling() {
      return this.$store.getters["user/getDefaultBillingAddress"];
    },
    defaultShipping() {
      return this.$store.getters["user/getDefaultShippingAddress"];
    },
    addressList() {
      if (typeof this.user.addresses != "undefined") {
        var addresses = [];
        this.user.addresses.forEach((address) => {
          if (
            address.id != this.defaultBilling.id &&
            address.id != this.defaultShipping.id
          ) {
            addresses.push(address);
          }
        });
        return addresses;
      }
      return [];
    },
  },
  methods: {
    newAddress() {
      this.editAddressId = -1;
    },
    Adjust(id) {
      this.$store.commit("user/setEditAddressId", id);
    },
    Remove(id) {
      this.$store.dispatch("user/deleteAddress", { id: id });
    },
    goToEditAddress(id) {
      //this.tabIndex = 3;
      this.$store.commit("user/setEditAddressId", id);
    },
    goBack() {
      this.$store.commit("user/setEditAddresses", false);
    },
  },
};
</script>

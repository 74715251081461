<template>
  <div>
    <h5 class="text-primary">{{ $t("newsletter_subscriptions2") }}</h5>
    <h6 class="border-bottom mt-2 pb-1">
      {{ $t("newsletter_subscription_option") }}
    </h6>
    <div class="row">
      <div class="col-sm-12 d-flex align-items-center">
        <b-form-checkbox
          id="checkbox-1"
          name="checkbox-1"
          label-for="checkbox1"
          v-model="is_subscribed"
        >
          {{ $t("general_subscription") }}
        </b-form-checkbox>
      </div>
      <div class="col-sm-12 text-right">
        <button @click="save" type="button" class="btn btn-dark">
          <span>{{ $t("save") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";

export default {
  data: () => ({
    is_subscribed: false,
  }),
  mounted() {
    this.is_subscribed = this.user.is_subscribed;
    Logger.debug("user", "NewsletterSubscription", this.user.is_subscribed)();
  },
  computed: {
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
  },
  watch: {
    user() {
      this.is_subscribed = this.user.is_subscribed;
    },
  },
  methods: {
    goBack() {
      this.$router.push({
        path: "/account/",
        query: { name: "DataAndPreferences" },
      });
    },
    async save() {
      const retval = await this.$store.dispatch("user/updateUserNewsletter", {
        is_subscribed: this.is_subscribed,
      });

      if (retval == true) {
        let msgText = "";
        if (this.is_subscribed == true) {
          msgText = this.$t("subscription_succesful");
        } else {
          msgText = this.$t("unsubscription_succesful");
        }
        const msg = {
          type: "success",
          title: "",
          text: msgText,
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
  },
};
</script>
